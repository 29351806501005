import React from "react"
import { Typography } from "antd"
import { getTechnology } from "../../lib/prismic/mapData"
import { groupBy, orderBy } from "lodash"
import moment from "moment/moment"
import { TechnologyCourses } from "./TechnologyCourses"
import { CoursesList } from "../CoursesList/CoursesList"
import { PrismicDocument } from "@prismicio/types"
import { slugifyTechnology } from "../../lib/prismic/common"
import { PrismicCourseOrContentfulProduct } from "../../lib/contentful/PrismicCourseOrContentfulProduct"
import { isOldPrismicCourse } from "../../lib/prismic/getCourse"
import { Entry } from "contentful"
import { TypeProductEntitySkeleton } from "../../lib/contentful/ContentfulVzdelanibudoucnostiTypes"
import { ProductsColorsItem } from "../../lib/contentful/product/getAllProducts"

const formatArray = (arr: string[]) => {
  let outStr = ""

  if (arr.length === 1) {
    outStr = arr[0]
  } else if (arr.length === 2) {
    outStr = arr.join(" a ")
  } else if (arr.length > 2) {
    outStr = arr.slice(0, -1).join(", ") + " a " + arr.slice(-1)
  }

  return outStr
}

export const GroupedCoursesByTechnology = ({ title, courses, children, productsColors }: Props) => {
  const groupedOpenCoursesByTechnology = groupBy(courses, (d) =>
    isOldPrismicCourse(d)
      ? (d as PrismicDocument<any>).data.technology.uid
      : (d as Entry<TypeProductEntitySkeleton, undefined, string>).fields.technology?.[0].sys.id
  )
  const groupedOpenCoursesByTechnologyEntries = Object.entries(groupedOpenCoursesByTechnology)

  const sortedGroupedOpenCoursesByTechnologyEntries = groupedOpenCoursesByTechnologyEntries.sort(([keyA], [keyB]) => {
    if (keyA === undefined || keyA === "undefined") return 1
    if (keyB === undefined || keyB === "undefined") return -1
    return 0
  })

  const openCoursesTechnologies = formatArray(sortedGroupedOpenCoursesByTechnologyEntries.map(([, d]) => getTechnology(d[0]).name).filter((d) => d))

  return (
    <>
      {sortedGroupedOpenCoursesByTechnologyEntries.length > 0 ? (
        <>
          <Typography.Title level={2}>{title || `Otevřené registrace. Nabízíme online kurzy ${openCoursesTechnologies}.`}</Typography.Title>

          {children || null}

          {sortedGroupedOpenCoursesByTechnologyEntries.map(([slug, documents]) => {
            const orderedDocuments = orderBy(documents, (d) =>
              moment(
                isOldPrismicCourse(d)
                  ? (d as PrismicDocument<any>).data.first_course_day
                  : (d as Entry<TypeProductEntitySkeleton, undefined, string>).fields.courseFirstDay
              )
            )
            const firstDocument = documents[0]
            if (!firstDocument) {
              return null
            }

            const technology = getTechnology(firstDocument)
            if (!technology) {
              return null
            }

            return (
              <TechnologyCourses key={slug} technology={technology} technologyDetailUrl={`/pocitacove-krouzky-pro-deti/${technology.slug}`}>
                <CoursesList
                  courses={orderedDocuments || []}
                  productsColors={productsColors}
                  withSubCourse={false}
                  whenOnHeader={true}
                  simpleLayout={true}
                  informationOnly={true}
                  carouselWidth={true}
                  colProps={{ xs: 12, sm: 8, md: 8, lg: 8, xl: 6 }}
                />
              </TechnologyCourses>
            )
          })}
        </>
      ) : null}
    </>
  )
}

interface Props {
  title: React.ReactNode
  courses: PrismicCourseOrContentfulProduct[]
  children?: React.ReactNode
  productsColors?: ProductsColorsItem[]
}
