import React from "react"
import styled from "@emotion/styled"
import { Typography } from "antd"
import { CourseTechnology } from "../../lib/prismic/mapData"
import { md } from "../../styles/mediaQuery"
import Link from "next/link"

export const TechnologyCourses = ({ technology, children, technologyDetailUrl }: Props) => {
  return (
    <TechnologyCoursesWrapper>
      <TechnologyTitle color={technology.color}>
        {technology.name?.length > 0 ? (
          <Typography.Title level={3}>
            <Link href={technologyDetailUrl}>{technology.name}</Link>
          </Typography.Title>
        ) : null}

        {technology.description?.length > 0 && (
          <>
            <Typography.Text>{technology?.description?.slice(0, 130)}...</Typography.Text>{" "}
            <Link href={technologyDetailUrl} title={technology.name}>
              více informací
            </Link>
          </>
        )}
      </TechnologyTitle>

      <Courses>{children}</Courses>
    </TechnologyCoursesWrapper>
  )
}

interface Props {
  technology: CourseTechnology
  children: React.ReactNode
  technologyDetailUrl: string
}

const TechnologyCoursesWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;

  ${md} {
    gap: 15px;
    flex-direction: column;
    margin-bottom: 20px;
  }
`

const TechnologyTitle = styled.div`
  width: ${175 * 2}px;
  height: 175px;

  ${md} {
    width: 100%;
    height: 100%;
  }
`

const Courses = styled.div`
  position: relative;
  width: 100%;
`
